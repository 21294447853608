.row-data {
  border: 0.5px solid #999;
  padding: 10px;
}

.question-description {
  /* border: 0.5px solid #999; */
  padding: 7px;
  /* margin: 20px; */
}

textarea:focus {
  outline: none !important;
  border: 0;
}

.question-background {
  /* background-image: url("https://api.sklcchapai.com/wp-content/uploads/2023/01/SKLC-Logo-Opacity-6-2.jpeg"); */
  /* background-repeat:no-repeat; */
  /* background-position: center; */

}

@media screen and (min-width: 1000px) {
  .question-list {
    text-align: right;
  }

  .row-data-result {
    border: 0.5px solid #999;
    padding: 10px;
  }

  .row-data-result-phone {
    display: none;
  }

  .question-height {
    height: 70vh;
  }
}

@media screen and (max-width: 1000px) {
  .question-list {
    text-align: center;
  }

  .previous-question {
    text-align: center;
  }

  .row-data-result {
    display: none
  }

  .row-data-result-phone {
    border: 3px solid #000;
    padding: 3px 10px;
    margin: 5px 0;
  }

  .row-data-result-phone2 {
    border-bottom: 0.5px solid #999;
    padding: 10px;
  }
}

audio::-webkit-media-controls-timeline-container {
  display: none !important;
}

audio::-webkit-media-controls-current-time-display {
  display: none !important;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

audio::-webkit-media-controls-timeline {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider-container {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

audio::-webkit-media-controls-seek-back-button {
  display: none !important;
}

audio::-webkit-media-controls-seek-forward-button {
  display: none !important;
}

audio::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

audio::-webkit-media-controls-rewind-button {
  display: none !important;
}

audio::-webkit-media-controls-return-to-realtime-button {
  display: none !important;
}

audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}


audio {
  width: 100px;
  height: 54px;
}

.active-bar {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
}

.passive-bar {
  padding: 10px;
  background-color: rgb(119, 118, 118);
  text-align: center;
  color: #fff;
  border: 1px solid #000;
  font-weight: bold;
  cursor: pointer;
}

.home-box {
  background-image: linear-gradient(80deg, #202020, #3d3d3d);

}

.home-box:hover {
  transform: scale(1.04);


}

.button-play {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 20px;
  border: 1px solid;
}

.image-modal {
  margin-top: 20vh;
}

.image-modal:hover {
  transform: scale(2);
  margin-top: 20vh;
}


.answer-option-style {
  padding: 10px 10px;
  cursor: pointer;
  border-bottom: 1px solid #999;
}

.active-option2 {
  font-weight: bold;
  padding: 0px 10px;
}

.wrong-option-result {
  font-weight: bold;
  padding: 0px 10px;
  background-color: #b21e1e;
  color: #fff;

}

.correct-option-result {
  font-weight: bold;
  background-color: #66fc03;
}

.wrong-option-result-2 {
  font-weight: bold;
  background-color: #b21e1e;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
}

.correct-option-result-2 {
  font-weight: bold;
  padding: 10px 10px;
  background-color: #66fc03;
  text-align: center;
}

.passive-option2 {
  padding: 0px 10px;
}

/* .active-option {
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #58a5ed;
  font-weight: bold;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
} */

.passive-option {
  border: 0.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
}


.font-size-12 {
  font-size: 12px;
}